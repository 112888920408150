import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import {
  AppBar,
  Toolbar,
  Button,
	Typography,
	IconButton
} from '@material-ui/core'

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  icon: {
    marginRight: 5
	},
	colorWhite: {
		color: 'white'
	}
}

const Menu = ({ classes, isAdmin, handlePassword }) => (
  <div className={classes.root}>
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.grow}>
          Calazacon
        </Typography>

        <Link to="/estudiantes/certificados">
          <Button color="inherit">
            <i className={`material-icons ${classes.icon}`}>account_box</i>
            Estudiantes Certificados
          </Button>
        </Link>

        <Link to="/estudiantes/reprobados">
          <Button color="inherit">
            <i className={`material-icons ${classes.icon}`}>account_box</i>
            Estudiantes Reprobados
          </Button>
        </Link>

        <Link to="/estudiantes">
          <Button color="inherit">
            <i className={`material-icons ${classes.icon}`}>account_box</i>
            Estudiantes
          </Button>
        </Link>

        {
          isAdmin && (
            <Fragment>
              <Link to="/examinadores">
                <Button color="inherit">
                  <i className={`material-icons ${classes.icon}`}>people</i>
                  Examinadores
                </Button>
              </Link>

              <Link to="/certificacion">
                <Button color="inherit">
                  <i className={`material-icons ${classes.icon}`}>library_books</i>
                  Certificaciones
                </Button>
              </Link>
            </Fragment>
          )
        }

				<IconButton onClick={handlePassword} className={classes.colorWhite}>
					<i className={`material-icons ${classes.icon}`}>https</i>
				</IconButton>

        <Link to="/">
          <Button color="inherit" onClick={e => localStorage.clear()}>
            <i className={`material-icons ${classes.icon}`}>exit_to_app</i>
            Salir
          </Button>
        </Link>

			
      </Toolbar>
    </AppBar>
  </div>
)

const mapStateToProps = state => ({
  isAdmin: state.users.me.isAdmin
})

export default connect(mapStateToProps)(withStyles(styles)(Menu))
