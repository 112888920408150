import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import Certificate from './views/Certificate'
import Student from './views/Student'
import Examiners from './views/Examiners'
import Inscription from './views/Inscription'
import InscriptionEdit from './views/InscriptionEdit'

import Login from './views/Login'
import Edit from './views/edit'

import StudentCertificados from './views/StudentCertificados'
import Reprobados from './views/Reprobados'

export default () => (
  <Router>
    <Switch>
      <Route path='/certificacion' exact component={Certificate} />
      <Route path='/estudiantes' exact component={Student} />
      <Route path='/examinadores' exact component={Examiners} />
      <Route path='/' exact component={Login} />
      <Route path='/inscripcion/:id' exact component={Inscription} />
      <Route path='/inscripcion/edit/:id' exact component={InscriptionEdit} />
      <Route path='/edit/:id' exact component={Edit} />

      <Route path='/estudiantes/certificados' exact component={StudentCertificados} />
      <Route path='/estudiantes/reprobados' exact component={Reprobados} />

    </Switch>
  </Router>

)
