import React from 'react'
import { connect } from 'react-redux'
import {
	TableRow,
	TableCell,
	Button,
} from '@material-ui/core'
import certificateAction from '../../actions/certificate.action'
import { BASE_URL_APP } from '../../config'

function generateUC (uc) {
	uc = parseInt(uc)
	let formatUc = ''

	for (let i = 1; i <= uc; i++) {
		formatUc += `UC${i}`

		if (uc !== i) {
			formatUc += ', '
		}

	}

	return formatUc
}

/*function esquema (code) {
	const array = [
		{ value: 'ATS-PRL-201703', name: 'ATS-PRL-201703 PREVENSION EN RIESGOS LABORALES' },
		{ value: 'ATS-ASI-201704', name: 'ATS-ASI-201704 ASISTENCIA EN SEGURIDAD INDUSTRIAL' },
		{ value: 'E-ADMABP-201709', name: 'E-ADMABP-201709 ACTIVIDADES DE DOCENCIA EN METODOLOGIA DE APRENDIZAJE BASADO EN PROYECTOS ABP' },
		{ value: 'AC-AAPRYD ACTIVIDADES', name: 'AC-AAPRYD ACTIVIDADES DE APOYO PARA LA PROMOSION EN RECREACION Y DEPORTES' },
		{ value: 'ATS-GEV-201707', name: 'ATS-GEV-201707 GESTION ESPECIALIZADA EN VENTAS'},
		{ value: 'ATS-CYMRN-201709', name: 'ATS-CYMRN-201709 CONSERVACION Y MANEJO DE RECURSOS NATURALES' },
		{ value: 'VACP-MEV-201703', name: 'VACP-MEV-201703 MANTENIMIENTO ELECTROMECANICO DE VEHICULOS' },
		{ value: 'VACP-MA-201703', name: 'VACP-MA-201703 MECATRONICA AUTOMOTRIZ' },
		{ value: 'CYVP-ASE-201709', name: 'CYVP-ASE-201709 ASISTENCIA EN COMERCIO EXTERIOR' },
		{ value: 'TYA-V-201607', name: 'TYA-V-201607 VENTAS' },
		{ value: 'VASP-RMMC-201703', name: 'VASP-RMMC-201703 REPERACION, MONTAJE Y MODIFICACION DE CARROCERIAS' },
		{ value: 'SF-AC-201707', name: 'SF-AC-201707 ASISTENCIA DE CONTABILIDAD' },
		{ value: 'ATS-GA-201707', name: 'ATS-GA-201707 GESTION ADMINISTRATIVA' },
	]
	
	let find = array.find(item => item.value === code)
	
	return find.name
}*/

const Item = ({ item, classes, onEdit, onDeleted, onCopy }) => (
	<TableRow key={item._id}>
		<TableCell component="th" scope="row">{item.name}</TableCell>
		<TableCell>{item.id_user.name}</TableCell>
		<TableCell>{generateUC(item.uc)}</TableCell>
		<TableCell>
			<div className={classes}>
				<Button color="primary" variant="contained" onClick={onEdit}>
					<i className="material-icons">edit</i>
				</Button>
				<Button color="primary" variant="contained" onClick={onDeleted}>
					<i className="material-icons">delete_sweep</i>
				</Button>
				<Button color="primary" variant="contained" onClick={onCopy}>
					<i className="material-icons">share</i>
				</Button>
			</div>
		</TableCell>
	</TableRow>
)

const mapDispatchToProps = (dispatch, ownProps) => ({
	onEdit() {
		dispatch(certificateAction.edit(ownProps.item))
		ownProps.toggleForm()
	},
	onDeleted() {
		dispatch(certificateAction.deleted(ownProps.item._id))
	},
	onCopy () {
		const el = document.createElement('textarea')
		el.value = `${BASE_URL_APP}/inscripcion/${ownProps.item._id}`
		el.setAttribute('readonly', '')
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
	}
})

export default connect(null, mapDispatchToProps)(Item)
