import React, { Component } from 'react'
import { connect } from 'react-redux'

import Layout from '../../components/Layout/Layout'
import TableUi from './Table'
import ModalForm from './ModalForm'
import usersAction from '../../actions/users.action'

class Examiners extends Component {

  constructor (props) {
    super(props)

    this.state = {
      isForm: false
    }

    this.handleForm = this.handleForm.bind(this)
  }

	componentDidMount () {
		this.props.fetch()
  }
  
  handleForm () {
    this.setState(state => ({ isForm: !state.isForm }))
  }

  render () {
    return (
      <Layout>
        <ModalForm
          onClose={this.handleForm}
          isForm={this.state.isForm}
        />
        <TableUi
          onOpen={this.handleForm}
        />
      </Layout>
    )
  }

}

const mapDispatchToProps = dispatch => ({
  fetch() {
    dispatch(usersAction.fetchUser())
  }
})

export default connect(null, mapDispatchToProps)(Examiners)