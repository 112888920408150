import React from 'react'
import { connect } from 'react-redux'
import {
	TableRow,
	TableCell,
	Button,
} from '@material-ui/core'
import userAction from '../../actions/users.action'

const Item = ({ item, classes, onEdit, onDeleted, onToggleBlock }) => (
	<TableRow key={item._id}>
		<TableCell component="th" scope="row">{item.document}</TableCell>
		<TableCell>{item.name}</TableCell>
		<TableCell>{item.email}</TableCell>
		<TableCell numeric>{item.celphone}</TableCell>
		<TableCell>
			<div className={classes}>
				<Button color="primary" variant="contained" onClick={onEdit}>
					<i className="material-icons">edit</i>
				</Button>
				<Button color="primary" variant="contained" onClick={onDeleted}>
					<i className="material-icons">delete_sweep</i>
				</Button>

				<Button color={!item.isPermit ? "primary" : "secondary"} variant="contained" onClick={onToggleBlock}>
					{
						!!item.isPermit ? (
							<i className="material-icons">lock_open</i>
						) : (
							<i className="material-icons">lock</i>
						)
					}
				</Button>
			</div>
		</TableCell>
	</TableRow>
)

const mapDispatchToProps = (dispatch, ownProps) => ({
	onEdit () {
		dispatch(userAction.edit(ownProps.item))
		ownProps.toggleForm()
	},
	onDeleted () {
		dispatch(userAction.deleted(ownProps.item._id))
	},
	onToggleBlock () {
		let object = {
			id: ownProps.item._id,
			isPermit: !ownProps.item.isPermit
		}

		dispatch(userAction.updatedPermit(object))
	}
})

export default connect(null, mapDispatchToProps)(Item)
