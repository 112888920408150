import React, { PureComponent } from 'react'
import socketIo from 'socket.io-client'
import Experience from './Experience'
import Training from './Training'
import Laboral from './Laboral'
import DatosEncuesta from './DatosEncuesta'
import Ciudadanos from './Ciudadanos'
import CondicionLaboral from './CondicionLaboral'
import CondicionVida from './CondicionVida'
import Studiant from './Studiant'

import initialState from './state'

import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import { BASE_URL } from '../../../config'
import { validCedula } from './valid'

class Form extends PureComponent {

	constructor(props) {
		super(props)

		this.state = initialState

		this.setDepthField = this.setDepthField.bind(this)
		this.setField = this.setField.bind(this)
		this.handleSave = this.handleSave.bind(this)

		this.openSnack = this.openSnack.bind(this)
		this.handleCloseSnack = this.handleCloseSnack.bind(this)
		this.changeFile = this.changeFile.bind(this)

		this.onFinish = this.onFinish.bind(this)
	}

	componentDidMount() {
		this.socket = socketIo(BASE_URL)

		this.socket.on('terminar::register', this.onFinish)
	}

	componentWillUnmount() {
		this.socket.close()
	}

	onFinish() {
		this.openSnack('Se ha finalizado el registro con exito')
		// window.location.href = '/'
	}

	changeFile(e) {
		let { file } = this.state
		file = e.target.files[0]
		this.setState({ file })
	}

	openSnack(name) {
		this.setState({
			open: true,
			message: name
		})
	}

	handleCloseSnack() {
		this.setState({
			open: false,
			message: ''
		})
	}

	setField(e) {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	setDepthField(e) {
		const { name, value } = e.target
		let newArray = name.split('-')
		let name1 = newArray[0]
		let name2 = newArray[1]
		this.setState(state => ({
			[name1]: Object.assign({}, state[name1], {
				[name2]: value
			})

		}))
	}

	handleSave() {
		if (this.valid()) {
			this.socket.emit('finalizar::inscripcion', {
				...this.state,
				inscripcionId: this.props.inscripcionId
			})
			document.querySelector('body').classList.add('Finished-active')
			this.setState({ isButton: true })
			this.openSnack('Se enviado....')
		}
	}

	valid() {
		if (!validCedula(this.state.document, this.openSnack)) {
			document.getElementById('document').focus()
			return false
		}
		else return true
	}

	render() {
		return (
			<section className="TableForm">
				{
					!!this.state.isButton && (
						<div className="Finished">
							<h2 className="Finished-title">Se ha registrado con éxito</h2>
						</div>
					)
				}
				<article className="TableForm-card">
					<h2 className="TableForm-title">
						1.- PROCESO DE CERTIFICACION
					</h2>
					<Studiant
						state={{
							name: this.state.name,
							document: this.state.document,
							birthdate: this.state.birthdate,
							direction: this.state.direction,
							province: this.state.province,
							city: this.state.city,
							phone: this.state.phone,
							celphone: this.state.celphone,
							email: this.state.email,
						}}
						setField={this.setField}
					/>
				</article>

				<article className="TableForm-card">
					<h2 className="TableForm-title">2.- FORMACIÓN ACTUAL</h2>
					<Experience
						state={{
							primaria: this.state.primaria,
							secundaria: this.state.secundaria,
							tecnico: this.state.tecnico,
							tercerNivel: this.state.tercerNivel,
							cuartoNivel: this.state.cuartoNivel,
						}}
						setDepthField={this.setDepthField}
					/>
				</article>

				<article className="TableForm-card">
					<h2 className="TableForm-title">3.- Capacitación o formación recibida</h2>
					<Training
						state={{
							capacitacion1: this.state.capacitacion1,
							capacitacion2: this.state.capacitacion2,
							capacitacion3: this.state.capacitacion3,
						}}
						setDepthField={this.setDepthField}
					/>
				</article>

				<article className="TableForm-card">
					<h2 className="TableForm-title">4.- Experiencia laboral</h2>
					<Laboral
						state={{
							experiencia1: this.state.experiencia1,
							experiencia2: this.state.experiencia2,
							experiencia3: this.state.experiencia3,
						}}
						setDepthField={this.setDepthField}
					/>
				</article>

				<article className="TableForm-card">
					<h2 className="TableForm-title">5.- DATOS GENERALES DEL ENCUESTADO</h2>
					<DatosEncuesta
						state={this.state.autoidentificacion}
						setField={this.setField}
					/>
				</article>

				<article className="TableForm-card">
					<h2 className="TableForm-title">6.- CIUDADANOS/AS OCUPADOS/AS</h2>
					<Ciudadanos
						state={{
							tipoOcupacion: this.state.tipoOcupacion,
							contrato: this.state.contrato,
						}}
						setField={this.setField}
					/>
				</article>

				<article className="TableForm-card">
					<h2 className="TableForm-title">
						7.- CONDICIONES LABORALES
						<span>RECIBE POR PARTE DE SU PATRONO O EMPLEADOR:</span>
					</h2>
					<CondicionLaboral
						state={{
							seguroMedio: this.state.seguroMedio,
							sueldoTrece: this.state.sueldoTrece,
							sueldoCatorce: this.state.sueldoCatorce,
							sueldo: this.state.sueldo,
							cambioPuesto: this.state.cambioPuesto,
							satisfechoEmpleo: this.state.satisfechoEmpleo,
							agotadoEmpleo: this.state.agotadoEmpleo,
							respetanTrabajo: this.state.respetanTrabajo,
							jefesRecoTrab: this.state.jefesRecoTrab,
							riesgoLaboral: this.state.riesgoLaboral,
							deseariaCambiarTrabajo: this.state.deseariaCambiarTrabajo,
						}}
						setField={this.setField}
					/>
				</article>

				<article className="TableForm-card">
					<h2 className="TableForm-title">
						8. DATOS DE CONDICIONES DE VIDA
					</h2>
					<CondicionVida
						state={{
							seguroMedico: this.state.seguroMedico,
							hijos: this.state.hijos,
							cuantoHijos: this.state.cuantoHijos,
							hijosMayorTres: this.state.hijosMayorTres,
							estudian: this.state.estudian,
							miembrosHogar: this.state.miembrosHogar,
							propiedad: this.state.propiedad,
							servicioBaSIco: this.state.servicioBaSIco,
							discapacidad: this.state.discapacidad,
							tipoDiscapacidad: this.state.tipoDiscapacidad,
							socioEmpleo: this.state.socioEmpleo,
						}}
						setField={this.setField}
					/>
				</article>

				<article className="TableForm-footer">
					<Button
						variant="contained"
						color="secondary"
						disabled={this.state.isButton}
						onClick={this.handleSave}
					>
						Guardar
					</Button>

					<input
						type="file"
						id="requisitos-pdf"
						accept="application/pdf"
						className="requisitos-input"
						onChange={this.changeFile}
					/>

					<Button
						variant="contained"
						color="secondary"
						style={{ cursor: 'pointer' }}
					>
						<label htmlFor="requisitos-pdf" style={{ cursor: 'pointer' }}>Subir requisitos</label>
					</Button>
				</article>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={this.state.open}
					autoHideDuration={1000}
					onClose={this.handleCloseSnack}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">{this.state.message}</span>}
				/>
			</section>
		)
	}
}

export default Form
