import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Typography } from '@material-ui/core'
import Layout from '../../components/Layout/Layout'
import TableUi from './TableApp'
import studentAction from '../../actions/student.action'

class Examiners extends Component {


	componentDidMount () {
		this.props.fetch()
  }
  
  render () {
    return (
      <Layout>
        <Typography variant="h6" style={{marginTop: 16}}>Estudiantes reprobados</Typography>
        <TableUi
          onAll={this.onAll}
          onOpen={this.handleForm}
          onCertificate={this.onCertificate}
        />
      </Layout>
    )
  }

}

const mapDispatchToProps = dispatch => ({
  fetch() {
    dispatch(studentAction.fetchStudentNoCertificado())
  }
})

export default connect(null, mapDispatchToProps)(Examiners)