import { BASE_URL } from '../config'

export default {

	fetchStudent: () => async (dispatch, getState) => {
		const response = await fetch(`${BASE_URL}/inscripciones`)
		const payload = await response.json()
		let user = getState().users.me
		dispatch({ type: 'STUDENT_FETCH', payload, user  })
	},

	fetchStudentCertificado: () => async (dispatch, getState)  => {
		const response = await fetch(`${BASE_URL}/student-certificados`)
		const payload = await response.json()
		let user = getState().users.me
		dispatch({ type: 'STUDENT_FETCH_CERTIFICADO', payload, user  })
	},

	fetchStudentNoCertificado: () => async (dispatch, getState)  => {
		const response = await fetch(`${BASE_URL}/student-no-certificados`)
		const payload = await response.json()
		let user = getState().users.me
		dispatch({ type: 'STUDENT_FETCH_CERTIFICADO_NO', payload, user })
	},


	finishRegister: payload => (dispatch, _, { emitSocket }) => {
		emitSocket('finish::register', payload)
	},

	deleted: payload => (dispatch, _, { emitSocket }) => {
		emitSocket('deleted::student', { id: payload })
	},

	onCertificado: payload => (dispatch, _, { emitSocket }) => {
		emitSocket('certificado::estudiante', payload)
	},
}
