import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from '../../components/Modal'
import usersAction from '../../actions/users.action'

import {
	TextField
} from '@material-ui/core'

const initialState = {
	name: '',
	document: '',
	email: '',
	celphone: ''
}

class ModalForm extends Component {

	constructor (props) {
		super(props)

		this.state = initialState

		this.setField = this.setField.bind(this)
		this.onSaved = this.onSaved.bind(this)
		this.onClose = this.onClose.bind(this)
	}

	componentWillReceiveProps (nextProps) {
		const { edit } = nextProps

		if (edit._id) {
			this.setState({
				document: edit.document || '',
				name: edit.name || '',
				email: edit.email || '',
				celphone: edit.celphone || '',
			})
		}
	}

	setField (e) {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	onSaved () {
		if (!this.props.edit._id) {
			this.props.savedUser(this.state)
		}
		else {
			this.props.updated({ ...this.state, _id: this.props.edit._id })
		}
		this.onClose()
	}

	onClose () {
		this.setState({ ...initialState })
		this.props.onEdit()
		this.props.onClose()
	}

	render () {
		return (
			<Modal
				title="Nuevo Examinador"
				isForm={this.props.isForm}
				onClose={this.onClose}
				onSaved={this.onSaved}
			>
				<TextField
					label="Cedula"
					name="document"
					variant="outlined"
					value={this.state.document}
					onChange={this.setField}
				/>
				<TextField
					label="Nombres completos"
					name="name"
					variant="outlined"
					value={this.state.name}
					onChange={this.setField}
				/>
				<TextField
					label="E-mail"
					name="email"
					variant="outlined"
					value={this.state.email}
					onChange={this.setField}
				/>
				<TextField
					label="Celular / Telefono"
					name="celphone"
					variant="outlined"
					value={this.state.celphone}
					onChange={this.setField}
				/>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
	edit: state.users.edit
})

const mapDispatchToProps = dispatch => ({
	savedUser(payload) {
		dispatch(usersAction.savedUser(payload))
	},
	updated(payload) {
		dispatch(usersAction.updated(payload))
	},
	onEdit() {
		dispatch(usersAction.edit({}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm)