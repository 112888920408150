import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Menu from './Menu'
import Password from './Password'

import { CALAZACON_USER_ID, CALAZACON_AUTH_TOKEN } from '../../constants'
import usersAction from '../../actions/users.action'



class Layout extends Component {

	constructor (props) {
		super(props)

		this.state = {
			isPassword: false,
		}

		this.handlePassword = this.handlePassword.bind(this)
	}

  componentWillMount() {
    if (!localStorage.getItem(CALAZACON_AUTH_TOKEN)) {
      this.props.history.push('/')
    }
    else {
      this.props.fetchMe(localStorage.getItem(CALAZACON_USER_ID))
    }
	}
	
	handlePassword () {
		this.setState(state => ({
			isPassword: !state.isPassword
		}))
	}

  render () {
    return (
      <Fragment>
        <Menu handlePassword={this.handlePassword} />
				<Password
					onClose={this.handlePassword}
					isActive={this.state.isPassword}
				/>

        <main>
          { this.props.children }
        </main>
        <footer>
          <p>© Copyright 2018, Desarrollado por <a href="https://jarl-alejandro.github.io/" target="_blank">Alejandro Rivas</a></p>
        </footer>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchMe(id) {
    dispatch(usersAction.fetchMe(id))
  }
})

export default connect(null, mapDispatchToProps)(withRouter(Layout))
