import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import { initSocket, emitSocket } from './websocket'

import users from './reducers/users.reducer'
import certificate from './reducers/certificate.reducer'
import student from './reducers/student.reducer'

function startUp () {
  const middleware = [thunk.withExtraArgument({ emitSocket })]

  const store = createStore(
    combineReducers({
      users,
      certificate,
      student
    }),
    applyMiddleware(...middleware)
  )

  initSocket(store)
  return store
}

export default startUp
