import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import TablePagination from '../../components/TablePagination'

import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core'
import Item from './Item'

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	action: {
		display: 'flex',
		justifyContent: 'space-around'
	},
	danger: {
		backgroundColor: '#F44336',
		color: 'white',
		'&:hover': {
			backgroundColor: '#F44336',
		},
	},
	title: {
		paddingLeft: 24,
		paddingTop: 24,
		paddingRight: 24,
		display: 'flex',
		justifyContent: 'space-between',
		flex: '0 0 auto',
	},
})

class TableUi extends Component {
	state = {
		page: 0,
		rowsPerPage: 5,
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	render() {
		const { classes, onOpen, table, onAll, onCertificate } = this.props
		const { rowsPerPage, page } = this.state

		return (
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell width="10%">Cedula</TableCell>
						<TableCell width="10%">Nombre</TableCell>
						<TableCell width="10%">E-mail</TableCell>
						<TableCell width="10%" numeric>Celular</TableCell>
						<TableCell width="10%">Certificación</TableCell>
						<TableCell width="10%"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						table.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
							<Item
								classes={classes.action}
								toggleForm={onOpen}
								key={item._id}
								item={item}
								onAll={onAll}
								onCertificate={onCertificate}
							/>
						))
					}
				</TableBody>

				<TablePagination
					colSpan={6}
					count={table.length}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>
			</Table>
		)
	}
}


export default withStyles(styles)(TableUi)
