import React, { PureComponent } from 'react'
import {
	Button,
	Typography
} from '@material-ui/core'
import classNames from 'classnames'
import './modal.scss'

class Modal extends PureComponent {

	constructor (props) {
		super(props)

		this.handleClose = this.handleClose.bind(this)
	}
	
	handleClose (e) {
		if (e.target.classList) {
			let array = [...e.target.classList]
			let classList = array.find(item => item === 'overlay-modal')
	
			if (classList) {
				this.props.onClose()
			}
		}
	}

	render () {
		const { title, children, isForm, onClose, onSaved } = this.props

		return (
			<section
				onClick={this.handleClose}
				className={classNames("overlay-modal", {
					"active": isForm
				})}
			>
				<section className={classNames("modal", {
					"active": isForm
				})}>
					<div className="modal-title">
						<Typography variant="h6">{ title }</Typography>
					</div>
					<article className="modal-grid">
						{ children }
					</article>
					<div className="modal-footer">
						<Button variant="outlined" color="primary" onClick={onClose}>Cerrar</Button>
						<Button variant="outlined" color="primary" onClick={onSaved}>Guardar</Button>
					</div>
				</section>
			</section>
		)
	}
}


export default Modal