import React from 'react'
import { connect } from 'react-redux'
import {
	TableRow,
	TableCell,
	Button,
} from '@material-ui/core'
import Score from '@material-ui/icons/Score'
import CloudDownload from '@material-ui/icons/CloudDownload'
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode'
import Event from '@material-ui/icons/Event'

import studentAction from '../../actions/student.action'
import { BASE_URL } from '../../config'

function formularioPDF(id) {
	let urlForm = `${BASE_URL}/pdf/${id}/formulario`
	let urlForm007 = `${BASE_URL}/pdf/${id}/formulario/012`

	window.open(urlForm, '_blank')
	window.open(urlForm007, '_blank')
}

function formularioPDFCertificate(id) {
	let urlForm = `${BASE_URL}/pdf/${id}/certificate`
	window.open(urlForm, '_blank')

}

const Item = ({ item, classes, toggleCertificate}) => (
	<TableRow key={item._id}>
		<TableCell component="th" scope="row">{item.document}</TableCell>
		<TableCell>{item.name}</TableCell>
		<TableCell>{item.email}</TableCell>
		<TableCell numeric>{item.celphone}</TableCell>
		<TableCell>{item.certificateId.name}</TableCell>
		<TableCell>
			<div className={classes}>
				<Button
					onClick={e => formularioPDF(item._id)}
					variant="contained" color="primary" className={classes.button}
				>
					<CloudDownload />
				</Button>
				<Button
					onClick={e => formularioPDFCertificate(item._id)}
					variant="contained" color="primary" className={classes.button}
				>
					<ChromeReaderMode />
				</Button>
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={e => toggleCertificate(item)}
				>
					<Event />
				</Button>
				{
					!!item.pdfRequisitos && (
						<a target="_blank" href={`${BASE_URL}/descargar-requisitos/${item.pdfRequisitos}`}>
							<Button variant="contained" color="primary" className={classes.button}>
								<Score />
							</Button>
						</a>
					)
				}
			</div>
		</TableCell>
	</TableRow>
)

const mapDispatchToProps = (dispatch, ownProps) => ({
	onDeleted() {
		dispatch(studentAction.deleted(ownProps.item._id))
	}
})

export default connect(null, mapDispatchToProps)(Item)
