import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'

import Layout from '../../components/Layout/Layout'
import TableUi from './TableApp'
import studentAction from '../../actions/student.action'
import Certificate from './Certificate'

class Examiners extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
      row: {}
    }

    this.toggleCertificate = this.toggleCertificate.bind(this)
  }

  componentDidMount() {
    this.props.fetch()
  }

  toggleCertificate(row) {
    this.setState(state => ({
      isActive: !state.isActive,
      row
    }))
  }

  
  render () {
    return (
      <Layout>
        <Typography variant="h6" style={{ marginTop: 16 }}>Estudiantes certificados</Typography>
        <TableUi
          toggleCertificate={this.toggleCertificate}
        />
        {
          !!this.state.isActive && (
            <Certificate
              isActive={this.state.isActive}
              row={this.state.row}
              toggleCertificate={this.toggleCertificate}
            />
          )
        }
      </Layout>
    )
  }

}

const mapDispatchToProps = dispatch => ({
  fetch() {
    dispatch(studentAction.fetchStudentCertificado())
  }
})

export default connect(null, mapDispatchToProps)(Examiners)