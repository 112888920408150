import React, { Component } from 'react'
import rn from 'random-number';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Form from './Form/Form'
import './style.scss'
import { BASE_URL } from '../../config'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#e1b62a"
		},
		secondary: {
			main: "#2d6741"
		},
	},
	status: {
		danger: 'orange',
	},
	typography: {
		useNextVariants: true,
	},
})

class Inscription extends Component {

	render () {
		return (
			<div className="Inscription">
				<header className="Inscription-header">
					<img src={`${BASE_URL}/logo/calazacon`} className="logo-inscripcion" />
				</header>
				<section className="Inscription-contained">
					<MuiThemeProvider theme={theme}>
						<Form inscripcionId={this.props.match.params.id} />
					</MuiThemeProvider>
				</section>

				<div className="absolute bottom-0 left-0 overflow-hidden right-0 top-0 bubbles-container">
					<div className="bottom-particles dn db-l">{renderBubbles()}</div>
				</div>

				<footer>
					<p>© Copyright 2018, Desarrollado por <a href="https://jarl-alejandro.github.io/" target="_blank">Alejandro Rivas</a></p>
				</footer>
			</div>
		)
	}
}

export default Inscription

const renderBubbles = () => {
	const bubbles = [
		'angular.png',
		'babel.png',
		'browserify.png',
		'eslint.png',
		'gulp.png',
		'isna.png',
		'jest.png',
		'jquery.png',
		'lodash.png',
		'mongodb.png',
		'nextjs.png',
		'npm.png',
		'postcss.png',
		'preact.png',
		'prettier.png',
		'react.png',
		'redux.png',
		'styled-components.png',
		'typescript.png',
		'vue.png',
		'webpack.png',
		'yarn.png'
	];
	const getRandomAnimationDelay = () => rn({ integer: true, min: 1, max: 8 });
	const getRandomAnimationDuration = () => rn({ integer: true, min: 10, max: 25 });
	const getRandomLeft = () => rn({ integer: true, min: -5, max: 105 });
	const getStyles = logo => ({
		animationDelay: `${getRandomAnimationDelay()}s`,
		animationDuration: `${getRandomAnimationDuration()}s`,
		backgroundImage: `url(${BASE_URL}/logo/calazacon)`,
		left: `${getRandomLeft()}%`
	});

	return bubbles.map((logo, index) => (
		<React.Fragment key={logo}>
			<div className="bubble" style={getStyles(logo, index)} />
		</React.Fragment>
	));
};