import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import RichTextEditor from 'react-rte'

import Modal from '../../components/Modal'
import usersAction from '../../actions/users.action'
import certificateAction from '../../actions/certificate.action'
import Editor from '../../components/Editor'

import {
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	OutlinedInput
} from '@material-ui/core'

const styles = theme => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	}
})

const initialState = {
	idUser: '',
	name: '',
	uc: '',
	sector: '',
	squemaCode: '',
	competitionUnits: RichTextEditor.createEmptyValue(),
	format: 'html',
}

class ModalForm extends Component {

	constructor (props) {
		super(props)

		this.state = initialState

		this.setField = this.setField.bind(this)
		this.onSaved = this.onSaved.bind(this)
		this.onClose = this.onClose.bind(this)
		this.onChangePulse = this.onChangePulse.bind(this)
	}

	componentWillReceiveProps (nextProps) {
		const { edit } = nextProps

		if (edit._id) {
			this.setState({
				idUser: edit.id_user._id || '',
				name: edit.name || '',
				uc: edit.uc || '',
				sector: edit.sector || '',
				squemaCode: edit.squemaCode || '',
				competitionUnits: RichTextEditor.createValueFromString(edit.competitionUnits || '', this.state.format),
			})
		}
	}

	setField (e) {
		const { name, value } = e.target
		this.setState({ [name]: value })
	}

	onSaved () {
		if (!this.props.edit._id) {
			this.props.savedCertificate({
				...this.state,
				competitionUnits: this.state.competitionUnits.toString(this.state.format),
			})

		}
		else {
			this.props.updatedCertificate({
				...this.state,
				_id: this.props.edit._id,
				competitionUnits: this.state.competitionUnits.toString(this.state.format),
			})
		}
		this.onClose()
	}

	onClose () {
		this.setState({ ...initialState })
		this.props.onEdit()
		this.props.onClose()
	}

	onChangePulse(name, value) {
		this.setState({ [name]: value })
	}

	render () {
		const { classes, users } = this.props
		return (
			<Modal
				title="Nueva Certificación"
				isForm={this.props.isForm}
				onClose={this.onClose}
				onSaved={this.onSaved}
			>
				<TextField
					label="Nombre de la certificación"
					name="name"
					variant="outlined"
					value={this.state.name}
					onChange={this.setField}
				/>
				<TextField
					label="Unidades de competenica"
					name="uc"
					variant="outlined"
					value={this.state.uc}
					onChange={this.setField}
				/>
				<TextField
					label="Sector de la certificación"
					name="sector"
					variant="outlined"
					value={this.state.sector}
					onChange={this.setField}
				/>

				<FormControl variant="outlined" className={`${classes.formControl}`}>
					<InputLabel htmlFor="squemaCode">Codigo de esquema</InputLabel>
					<Select
						value={this.state.squemaCode}
						onChange={this.setField}
						input={
							<OutlinedInput
								labelWidth={0}
								name="squemaCode"
								id='squemaCode'
							/>
						}
					>
						<MenuItem value='ATS-PRL-201703'>ATS-PRL-201703 PREVENSION EN RIESGOS LABORALES</MenuItem>
						<MenuItem value='ATS-ASI-201704'>ATS-ASI-201704 ASISTENCIA EN SEGURIDAD INDUSTRIAL</MenuItem>
						<MenuItem value='E-ADMABP-201709'>E-ADMABP-201709 ACTIVIDADES DE DOCENCIA EN METODOLOGIA DE APRENDIZAJE BASADO EN PROYECTOS ABP</MenuItem>
						<MenuItem value='AC-AAPRYD'>AC-AAPRYD ACTIVIDADES DE APOYO PARA LA PROMOSION EN RECREACION Y DEPORTES</MenuItem>

						<MenuItem value='THYS-OAAMO-201603'>THYS-OAAMO-201603 ASISTENCIA ADMINISTRATIVA CON MANEJO DE OFIMÁTICA</MenuItem>
						<MenuItem value='TUR-AEA-201606-012-011'>TUR-AEA-201606-012-011 ADMINISTRACIÓN DE EMPRESAS</MenuItem>
						<MenuItem value='TYA-VEIT-201707'>TYA-VEIT-201707 VENTAS E INFORMACIÓN TURÍSTICA</MenuItem>
						<MenuItem value='TYA-PGC-201705'>TYA-PGC-201705 PREPARACIONES GASTRONÓMICAS DE COCINA</MenuItem>
						<MenuItem value='TDA-IPA-201708'>TDA-IPA-201708 INDUSTRIALIZACIÓN DE PRODUCTOS ALIMENTICIOS</MenuItem>
						<MenuItem value='INF-ABD-201607'>INF-ABD-201607 ADMINISTRACIÓN DE BASES DE DATOS</MenuItem>
					</Select>
				</FormControl>

				<FormControl className={`${classes.formControl}`}>
					<InputLabel htmlFor="idUser">Examinador</InputLabel>
					<Select
						value={this.state.idUser}
						onChange={this.setField}
						input={
							<OutlinedInput
								labelWidth={0}
								name="idUser"
								id='idUser'
							/>
						}
					>
						{!!users.isLoading && (
							<MenuItem value=''>Cargando...</MenuItem>
						)}
						{!users.isLoading && !users.payload.length && (
							<MenuItem value=''>No hay examinadores</MenuItem>
						)}
						{users.payload.map(item => (
							<MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<div className={`${classes.gridComplete} ${classes.marginTop}`}>
					<InputLabel>Unidades de competenica</InputLabel>
					<div className={classes.paddingBottom} />
					<Editor
						value={this.state.competitionUnits}
						name="competitionUnits"
						onChangePulse={this.onChangePulse}
					/>
				</div>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
	edit: state.certificate.edit,
	users: state.users.users,
})

const mapDispatchToProps = dispatch => ({
	savedCertificate(payload) {
		dispatch(certificateAction.savedCertificate(payload))
	},
	updatedCertificate(payload) {
		dispatch(certificateAction.updatedCertificate(payload))
	},
	onEdit() {
		dispatch(usersAction.edit({}))
	},
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ModalForm))
