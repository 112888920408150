import React, { Component } from 'react'
import Layout from '../../components/Layout/Layout'
import Form from './Form/Form'
import './style.css'

class EditCertificate extends Component {

	render () {
		return (
			<Layout>
				<div
					className="Inscription EditCertificate"
					style={{
						background: 'inherit',
						color: 'black',
					}}
				>
					<section
						style={{
							paddingTop: 0
						}}
						className="Inscription-contained"
					>
						<Form inscripcionId={this.props.match.params.id} />
					</section>
				</div>
			</Layout>
		)
	}
}

export default EditCertificate
