import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import TablePagination from '../../components/TablePagination'

import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	Button,
	Typography
} from '@material-ui/core'
import Item from './Item'

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	action: {
		display: 'flex',
		justifyContent: 'space-around'
	},
	danger: {
		backgroundColor: '#F44336',
		color: 'white',
		'&:hover': {
			backgroundColor: '#F44336',
		},
	},
	title: {
		paddingLeft: 24,
		paddingTop: 24,
		paddingRight: 24,
		display: 'flex',
		justifyContent: 'space-between',
		flex: '0 0 auto',
	},
})

class TableUi extends Component {
	state = {
		page: 0,
		rowsPerPage: 5,
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	render () {
		const { classes, onOpen, users } = this.props
		const { rowsPerPage, page } = this.state

		return (
			<Paper className={classes.root}>
				<div className={classes.title}>
					<Typography variant="h6">Examinadores</Typography>
					<Button onClick={onOpen} color="secondary" variant="contained">Agregar</Button>
				</div>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Cedula</TableCell>
							<TableCell>Nombre</TableCell>
							<TableCell>E-mail</TableCell>
							<TableCell numeric>Celular</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							users.payload.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
								<Item
									classes={classes.action}
									toggleForm={onOpen}
									key={item._id}
									item={item}
								/>
							))
						}
					</TableBody>

					<TablePagination
						colSpan={6}
						count={users.payload.length}
						rowsPerPage={this.state.rowsPerPage}
						page={this.state.page}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
					/>
				</Table>
			</Paper>
		)
	}
}

const mapStateToProps = state => ({
	users: state.users.users,
})

export default connect(mapStateToProps)(withStyles(styles)(TableUi))
