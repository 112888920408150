export default {
	name: '',
	document: '',
	birthdate: '',
	direction: '',
	province: '',
	city: '',
	phone: '',
	celphone: '',
	email: '',

	file: '',
	primaria: {
		name: '',
		pais: '',
		ciudad: '',
		titulo: ''
	},
	secundaria: {
		name: '',
		pais: '',
		ciudad: '',
		titulo: ''
	},
	tecnico: {
		name: '',
		pais: '',
		ciudad: '',
		titulo: ''
	},
	tercerNivel: {
		name: '',
		pais: '',
		ciudad: '',
		titulo: ''
	},
	cuartoNivel: {
		name: '',
		pais: '',
		ciudad: '',
		titulo: ''
	},
	capacitacion1: {
		nameCourse: '',
		nameInstitucion: '',
		dateCourse: '',
		hourCourse: '',
	},
	capacitacion2: {
		nameCourse: '',
		nameInstitucion: '',
		dateCourse: '',
		hourCourse: '',
	},
	capacitacion3: {
		nameCourse: '',
		nameInstitucion: '',
		dateCourse: '',
		hourCourse: '',
	},
	experiencia1: {
		desde: '',
		hasta: '',
		name: '',
		direction: '',
		phone: '',
		funcion: ''
	},
	experiencia2: {
		desde: '',
		hasta: '',
		name: '',
		direction: '',
		phone: '',
		funcion: ''
	},
	experiencia3: {
		desde: '',
		hasta: '',
		name: '',
		direction: '',
		phone: '',
		funcion: ''
	},
	autoidentificacion: '',
	tipoOcupacion: '',
	contrato: '',
	seguroMedio: '',
	sueldoTrece: '',
	sueldoCatorce: '',
	sueldo: '',
	cambioPuesto: '',
	satisfechoEmpleo: '',
	agotadoEmpleo: '',
	respetanTrabajo: '',
	jefesRecoTrab: '',
	riesgoLaboral: '',
	deseariaCambiarTrabajo: '',
	seguroMedico: '',
	hijos: '',
	cuantoHijos: '',
	hijosMayorTres: '',
	estudian: '',
	miembrosHogar: '',
	propiedad: '',
	servicioBasico: '',
	discapacidad: '',
	tipoDiscapacidad: '',
	socioEmpleo: '',

	open: false,
	isButton: false,
	message: '',
}
