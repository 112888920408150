import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'

import Layout from '../../components/Layout/Layout'
import TableUi from './TableApp'
import ModalForm from './ModalForm'
import studentAction from '../../actions/student.action'
import AllForm from './AllForm'
import Certificate from './Certificate'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import CloudDownload from '@material-ui/icons/CloudDownload'
import { BASE_URL } from '../../config'

const styles = theme => ({
	buttonFloat: {
		position: 'fixed',
		bottom: 20,
		right: 16
	}
})

class Examiners extends Component {

	constructor (props) {
		super(props)

		this.state = {
			isForm: false,
			row: {},
			isAll: false,
			isEdit: false,
			rowCertificate: {},
			isCertificate: false
		}

		this.handleForm = this.handleForm.bind(this)
		this.onAll = this.onAll.bind(this)
		this.closeAllForm = this.closeAllForm.bind(this)
		this.onCertificate = this.onCertificate.bind(this)
		this.closeCertificate = this.closeCertificate.bind(this)
	}

	componentDidMount () {
		this.props.fetch()
	}
	
	handleForm () {
		this.setState(state => ({ isForm: !state.isForm }))
	}

	onAll(row, isEdit = false) {
		this.setState({ row, isAll: true, isEdit })
	}

	closeAllForm() {
		this.setState({ row: {}, isAll: false, isEdit: false })
	}

	onCertificate(row) {
		this.setState({ rowCertificate: row, isCertificate: true })
	}

	closeCertificate() {
		this.setState({ rowCertificate: {}, isCertificate: false })
	}

	render () {
		return (
			<Layout>
				<ModalForm
					onClose={this.handleForm}
					isForm={this.state.isForm}
				/>
				<Typography variant="h6" style={{ marginTop: 16 }}>Estudiantes</Typography>
				<TableUi
					onAll={this.onAll}
					onOpen={this.handleForm}
					onCertificate={this.onCertificate}
				/>
				{
					this.state.isAll && (
						<AllForm
							row={this.state.row}
							isActive={this.state.isAll}
							isEdit={this.state.isEdit}
							closeAllForm={this.closeAllForm}
						/>
					)
				}
				<Certificate
					row={this.state.rowCertificate}
					isActive={this.state.isCertificate}
					closeCertificate={this.closeCertificate}
				/>

				<a href={`${BASE_URL}/excel`}>
					<Button variant="fab" color="secondary" className={this.props.classes.buttonFloat}>
						<CloudDownload />
					</Button>
				</a>
			</Layout>
		)
	}

}

const mapDispatchToProps = dispatch => ({
	fetch() {
		dispatch(studentAction.fetchStudent())
	}
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(Examiners))
