
/*

export const BASE_URL = 'http://localhost:8001'
export const BASE_URL_APP = 'http://localhost:3000'
export const BASE_URL_MEDIA = 'http://localhost:8001/media/'

*/



export const BASE_URL = 'https://app.itscalazaconoec.club'
export const BASE_URL_APP = 'https://itscalazaconoec.club'
export const BASE_URL_MEDIA = 'https://app.itscalazaconoec.club/media/'
