import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Router from './Router'
import teal from '@material-ui/core/colors/teal'
import lime from '@material-ui/core/colors/lime'

import * as serviceWorker from './serviceWorker'
import store from './store'

import './index.scss'


const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: lime,
  },
  status: {
    danger: 'orange',
  },
  typography: {
    useNextVariants: true,
  },
})


const App = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store()}>
      <Router />
    </Provider>
  </MuiThemeProvider>
)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
