
function parser(payload, user) {
	let data = {}

	console.log(payload)
	console.log(user)

	payload = user.isAdmin
		? payload
		: payload.filter(item => item.certificateId.id_user === user._id)

	payload.map(item => {
		let type = 'certificateId'
		let typeName = 'Certificación'
		let category = item[type]

		data[category._id] = {
			category: {
				id: category._id,
				name: `${typeName}: ${category.name}`
			},
			payload: !!data[category._id]
				? [...data[category._id].payload, item]
				: [item]
		}

	})

	return data
}

export default parser
