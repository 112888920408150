import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
	TableRow,
	TableCell,
	Button,
} from '@material-ui/core'
import Score from '@material-ui/icons/Score'
import CloudDownload from '@material-ui/icons/CloudDownload'
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode'
import Edit from '@material-ui/icons/Edit'
import BorderColor from '@material-ui/icons/BorderColor'

import studentAction from '../../actions/student.action'
import { BASE_URL, BASE_URL_APP } from '../../config'

function formularioPDF(id) {
	let urlForm = `${BASE_URL}/pdf/${id}/formulario`
	let urlForm007 = `${BASE_URL}/pdf/${id}/formulario/012`

	window.open(urlForm, '_blank')
	window.open(urlForm007, '_blank')
}

function formularioPDFCertificate(id) {
	let urlForm = `${BASE_URL}/pdf/${id}/certificate`
	window.open(urlForm, '_blank')

}

const Item = ({ item, classes, onDeleted, onAll, onCertificate, onCopy }) => (
	<TableRow key={item._id}>
		<TableCell component="th" scope="row">{item.document}</TableCell>
		<TableCell>{item.name}</TableCell>
		<TableCell>{item.email}</TableCell>
		<TableCell numeric>{item.celphone}</TableCell>
		<TableCell>{item.certificateId.name}</TableCell>
		<TableCell>
			<div className={classes}>
				{
					!item.isAll ? (
						<Button variant="contained"
							color="primary"
							onClick={e => onAll(item)}
							className={classes.button}
							style={{ marginRight: 10 }}
						>
							<CloudDownload />
						</Button>
					) : (
						<Fragment>
							<Button
								onClick={e => formularioPDF(item._id)}
								variant="contained" color="primary" className={classes.button}
								style={{ marginRight: 10 }}
							>
								<CloudDownload />
							</Button>
								{
									!item.isCertificateSetec ? (
										<Button
											style={{ marginRight: 10 }}
											onClick={e => onCertificate(item)}
											variant="contained" color="primary" className={classes.button}
										>
											<ChromeReaderMode />
										</Button>
									) : (
										<Button
											onClick={e => formularioPDFCertificate(item._id)}
											variant="contained" color="primary" className={classes.button}
											style={{ marginRight: 10 }}
										>
											<ChromeReaderMode />
										</Button>
									)
								}
						</Fragment>
					)
				}
				
				<Button
					color="primary"
					variant="contained"
					style={{ marginRight: 10 }}
					onClick={e => onAll(item, true)}
				>
					<i className="material-icons">aspect_ratio</i>
				</Button>

				{
					!!item.pdfRequisitos && (
						<a target="_blank" href={`${BASE_URL}/descargar-requisitos/${item.pdfRequisitos}`}>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								style={{ marginRight: 10 }}
							>
								<Score />
							</Button>
						</a>
					)
				}

				<Button
					onClick={onCopy}
					variant="contained"
					color="primary"
					className={classes.button}
					style={{ marginRight: 10 }}
				>
					<BorderColor />
				</Button>

				<Button
					color="primary"
					variant="contained"
					onClick={onDeleted}
					style={{ marginRight: 10 }}
				>
					<i className="material-icons">delete_sweep</i>
				</Button>

				<Link to={`/edit/${item._id}`}>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
					>
						<Edit />
					</Button>
				</Link>

			</div>
		</TableCell>
	</TableRow>
)

const mapDispatchToProps = (dispatch, ownProps) => ({
	onDeleted() {
		dispatch(studentAction.deleted(ownProps.item._id))
	},
	onCopy() {
		const el = document.createElement('textarea')

		el.value = `${BASE_URL_APP}/inscripcion/edit/${ownProps.item._id}`
		el.setAttribute('readonly', '')
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
	}
})

export default connect(null, mapDispatchToProps)(Item)
